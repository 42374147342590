<template>
  <div>
      <graphSell ref="sellC"></graphSell>
      <br> 
      <vx-card>
         <vs-table
            v-model="selected"
            
            pagination
            max-items="10"
            search
            :data="listVente" 
            noDataText="Aucun résultat"
            >

                <template slot="header">
                    <h3>Ventes</h3>
                </template>

                <template slot="thead">
                    <vs-th sort-key="nom">Nom Produit</vs-th>
                    <vs-th sort-key="credit">Nb Credit</vs-th>
                    <vs-th sort-key="prix">Prix HT</vs-th>
                    <vs-th sort-key="nbVente">Nb vente</vs-th>
                    <vs-th sort-key="avoir">Nb avoir</vs-th>
                    <vs-th sort-key="totalH">Total HT</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].nom">
                            {{ data[indextr].nom }}
                        </vs-td>
                        <vs-td :data="data[indextr].credit">
                            {{ data[indextr].credit }}
                        </vs-td>
                        <vs-td :data="data[indextr].prix" class="nowrap">
                            <template v-if="data[indextr].prix<0">
                                {{ (data[indextr].prix*-1).toFixed(2) }}
                            </template>
                            <template v-else>
                                {{ data[indextr].prix.toFixed(2) }}
                            </template>
                        </vs-td>
                        <vs-td :data="data[indextr].nbVente">
                            {{ data[indextr].nbVente }}
                        </vs-td>
                        <vs-td :data="data[indextr].avoir">
                                {{ data[indextr].avoir }}
                        </vs-td>
                        <vs-td :data="data[indextr].totalH">
                                {{ data[indextr].totalH.toFixed(2) }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
       </vx-card>
  </div>
  
</template>

<script>
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate'
import graphFacture from '@/components/stats/FacturationSeanceGraph.vue'
import accessGraph from '@/components/stats/FacturationAccessGraph.vue'
import graph from '@/components/stats/graphFacture.vue'
import graphSell from '@/components/stats/components/graphSellsComp.vue'

import Terminal from '@/database/models/terminal'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Member from '@/database/models/member'

import BoxVente from '@/components/app/boxVente'
import cDate from '@/components/app/date'
import VxCard from '@/components/vx-card/VxCard.vue'


export default {
    name:'factures',
    components: {
        BoxVente,
        cDate,
        VxCard,
        graphFacture,
        accessGraph,
        graph,
        graphSell,
    },
    props:[
        'shared',
        // shared change bien, mais comment modifier celui du this !! 
    ],
    data(){
        return{
           
            terminal_uid :this.shared.terminal_uid,
            terminal_name:this.shared.terminal_name,
            terminal_list:this.shared.terminal_list,

            list_facture:this.shared.list_facture,
            selected:this.shared.selected,

            list_type:this.shared.list_type,

            list_date: this.shared.list_date,


            vente_seance:this.shared.vente_seance,
            vente_accesoire:this.shared.vente_accesoire,
            vente_ca_mensuel:this.shared.vente_ca_mensuel,
            vente_ca_annuel:this.shared.vente_ca_annuel,

            admin:this.shared.admin,
            listAll:{
              listF:    [],
              listName: [],
              listQ:    [],
              listTHT:  [],
              listAv:   [],
              listCredit:[],
              listPrix:[],
            },
            listVente:[],
        }
    },
    watch:{
        list_date:function(){
           // this.changeDate();
           //console.log(" date cliquer : ", this.list_date)
            // Mon Feb 01 2021 19:53:00 GMT+0100 (CET) 
        },
        vente_ca_mensuel:function(){
           // console.log(" mois ca changed")
        },
        list_facture:function(){
          //console.log(" list facture changed : ", this.list_facture)
          this.listFiltre();
          this.$refs.sellC.getData(this.list_facture);
        },
        listAll:function(){
          // console.log(" list all changed : ", this.listAll)
        }

        
        
    },
	mounted:function()
	{
       
        this.RoleAdmin();
        ListRefresh(this);
        frefresh(this);
	},
    methods:{
        millier:Utils.Millier,
        //change terminal
        
        changeTerminal:function(elem)
        {
            this.terminal_uid=elem;
            //console.log(" parent termina changed ", this.terminal_uid)
            if(this.$route.params.uid != this.terminal_uid)
            {
                this.$router.push('/admin/sellsView/'+this.terminal_uid);
                this.list_facture = [];
                frefresh(this);
            }
            return;
        },
        
        // log(){
        //     console.log("this is the shared : ", this.shared, " and this data  ", this.$data);
        // },
        // listChange(event)
        // {
        //    //console.log(" recu : ", event);
        // },
        changeDate:function(alldata){
           // console.log(" essai all data ", alldata.vente_ca_mensuel)
            //console.log(" parent date changed ", "actuel : ", alldata.list_date)
            this.transferInfo(alldata);
           // console.log(" after : ", this.list_date);
            frefresh(this)
            //console.log(" lst fact : ", this.list_facture)
            
        },
        changeType(alldata){
            //console.log(" parent type changed ")
            this.transferInfo(alldata);
            frefresh(this)
        },
        refresh:function(){
            ListRefresh(this);
        },
        handleSelected()
        {
            //voir facture
            this.$router.push('/admin/invoice/'+this.selected.uid);
        },
        transferInfo(alldata){
          // console.log(" all data === == = ", alldata)
            this.terminal_uid =alldata.terminal_uid,
            this.terminal_name=alldata.terminal_name,
            this.terminal_list=alldata.terminal_list,

            this.list_facture=alldata.list_facture,
            this.selected=alldata.selected,

            this.list_type=alldata.list_type,

            this.list_date= alldata.list_date;


            this.vente_seance=alldata.vente_seance,
            this.vente_accesoire=alldata.vente_accesoire,
            this.vente_ca_mensuel=alldata.vente_ca_mensuel,
            this.vente_ca_annuel=alldata.vente_ca_annuel,

            this.admin=alldata.admin
        },

        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },
        listFiltre(){
          this.listAll.listF=this.list_facture; // on introduit d'abord la list des factures .
          let lName=[];
          let listQte = [];
          let totaux = [];
          let lA=[];
          let lc=[];
          let lp=[];
          //console.log(" this is list facture ::: :-----  ", this.list_facture)
          for (let i=0; i<this.list_facture.length; i++){
              if(this.list_facture[i].type!="Reçu"){
                // on récupere le nom des produits et leurs quantité 
                let ite = this.list_facture[i].item;
                for (let j=0; j<ite.length; j++){
                  let it = ite[j];
                  if(lName.includes(it.name)==true){
                    //console.log(" elem exist ")
                    let x = -1;
                    for (let j=0; j < lName.length; j++){
                      if (lName[j]==it.name){
                          x=j;
                      }
                    }
                    
                    
                    totaux[x]+=it.prix;
                    if(it.type=="av"){
                      lA[x]=lA[x]+1;
                  
                    }
                    else{
                         listQte[x]=listQte[x]+1;
                    }
                    
                  }
                  else{
                    lName.push(it.name);
                    
                    totaux.push(it.prix);
                    lc.push(it.credit);
                    lp.push(it.prix);
                    if(it.type=="av"){
                      lA.push(1);
                      listQte.push(0);
                    }
                    else{
                      lA.push(0)
                      listQte.push(1);
                    }
                  }
                }
              }
              
          }
        
          this.listAll.listName= lName; // on récupere la list des produits 
          this.listAll.listQ=listQte;   // on recupère la liste des quantités des produits respective 
          this.listAll.listTHT=totaux;  // on recupère les totaux hors taxes
          this.listAll.listAv=lA;       // on recuperes la liste des avoirs
          this.listAll.listCredit=lc;   // on recupère la liste des credits 
          this.listAll.listPrix=lp;     // on recupère la liste des prix

        let grosseListe=[];
          // on essaye de mettre en tableau 
          for (let n=0; n<this.listAll.listName.length; n++){
            let aux ={
              nom:this.listAll.listName[n],
              credit:this.listAll.listCredit[n],
              prix:this.listAll.listPrix[n],
              nbVente:this.listAll.listQ[n],
              avoir:this.listAll.listAv[n],
              totalH:this.listAll.listTHT[n],
            }
            grosseListe.push(aux);
          }
          this.listVente=grosseListe;
          
        },
    }
};

//refresh list terminal
function ListRefresh(root)
{
    //---------------------------------
    //liste terminal
    Terminal.getAllMemory( function(fbuf)
    {
        let tab = [];
        //global terminal
        tab.push({
            value : 'all',
            text  : 'Tout',
        });

        //add terminal
        for( const i in fbuf )
        {
            //add change
            tab.push({
                value : fbuf[i].uid,
                text  : fbuf[i].mac,
            });

            //find actu
            if( root.$route.params.uid==fbuf[i].uid )
            {
                root.terminal_uid  = fbuf[i].uid;
                root.terminal_name = fbuf[i].mac;
            }
            if(root.$route.params.uid == 'all')
            {
                root.terminal_uid  = 'all';
                root.terminal_name = 'Tout';
            }
        }
        root.terminal_list = tab;
    });
}


//Facture in terminal
function frefresh(root)
{
    root.$refs.sellC.frefresh();
    
    //console.log(" called ", root.list_date)
    //---------------------------------
    //List facture
    Invoice.getAllMemory( function(fbuf)
    {
        //vider mémoire
        root.list_facture = [];

        //stats
        state(fbuf, root)

        //complete data
        function completeDate(buffer)
        {
            //filtre terminal
            if(buffer.terminal!=root.$route.params.uid )
            if(root.$route.params.uid!='all'){
              
                return
            }
            //filtre verif type
            if(root.list_type != "all")
            if(buffer.type != root.list_type )
                return
            
            //filtre par date
            if(root.list_date != null)
            {
                let tfmin = (new Date( root.list_date.getFullYear(), root.list_date.getMonth(), 1 )).getTime()
                let tfmax = (new Date( root.list_date.getFullYear(), root.list_date.getMonth()+1, 1 )).getTime()
                let facd  = (new Date(buffer.createAt)).getTime()
                if( facd < tfmin )
                    return
                if( facd > tfmax)
                    return
            }


            Member.getMemory( buffer.member, function(mbr)
            {
                //Si pas de membre ?
                let p_member = { first_name:'Aucun', last_name:'' };
                if(mbr)
                    p_member = mbr;
                
                //get vendor
                Member.getMemory( buffer.vendor, function(ved)
                {
                    let p_vendor = { first_name:'Aucun', last_name:'' };
                    if(ved)
                        p_vendor = ved;

                    //payement
                    let paye = []
                    if(buffer.pay_money!=0)
                        paye.push({name:'ESP', value:buffer.pay_money})

                    if(buffer.pay_cb!=0)
                        paye.push({name:'CB',value:buffer.pay_cb})

                    if(buffer.pay_check!=0)
                        paye.push({name:'CHEQ',value:buffer.pay_check})

                    if(buffer.pay_dif!=0)
                        paye.push({name:'DIFF',value:buffer.pay_dif})

                    if(buffer.pay_prele!=0)
                        paye.push({name:'PREL',value:buffer.pay_prele})

                    //Item
                    Item.getTabAllMemoryKey( buffer.uid, function(items)
                    {
                        
                        //add
                        root.list_facture.push({
                            uid      : buffer.uid,
                            type     : Invoice.getTypeName(buffer),
                            date     : Fdate.getFullDateMin(buffer.createAt,true)+" "+Fdate.getHeure(buffer.createAt),
                            vdate    : Fdate.getFullDateMin(buffer.createAt),
                            number   : buffer.number,
                            vendor   : {'first_name':Utils.StrCapital(p_vendor.first_name), 'last_name':Utils.StrCapital(p_vendor.last_name)},
                            member   : {'first_name':Utils.StrCapital(p_member.first_name), 'last_name':Utils.StrCapital(p_member.last_name)},
                            item     : items,
                            payement : paye,
                            total    : (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele).toFixed(2),
                        });
                    })
                });
            });
        }

        //lister facture
        for( var i in fbuf )
            completeDate(fbuf[i]);

       
    });

}


function state( list_fac, root ){

    let date_actu = new Date(root.list_date)

    let Amin = (new Date( date_actu.getFullYear(), 0, 1, 0, 0, 0 )).getTime()
    let Amax = (new Date( date_actu.getFullYear()+1, 0, 1, 0, 0, 0 )).getTime()

    let Mmin = (new Date( date_actu.getFullYear(), date_actu.getMonth(), 1, 0, 0, 0 )).getTime()
    let Mmax = (new Date( date_actu.getFullYear(), date_actu.getMonth()+1, 1, 0, 0, 0)).getTime()

    //reset box state
    root.vente_ca_annuel = 0
    root.vente_ca_mensuel = 0
    root.vente_seance = 0
    root.vente_accesoire = 0

    for( var i in list_fac )
    {
        //get facture
        let buffer = list_fac[i]
        let total = (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele)

        let facd  = (new Date(buffer.createAt)).getTime()

        //Calcule CA anné
        if(facd>=Amin)
        if(facd<=Amax)
            root.vente_ca_annuel += total

        //Calcule CA mois
        if(facd>=Mmin)
        if(facd<=Mmax)
        {
            //CA mois
            root.vente_ca_mensuel += total
        }
    }

    //Item
    Item.getAllMemory( function(items)
    {
        for( var p in items)
        {
            let buffer = items[p]
            let itdate  = (new Date(buffer.createAt)).getTime()

            //Calcule CA mois
            if(itdate>=Mmin)
            if(itdate<=Mmax)
            if(buffer.prix!=0)
            if(buffer.tva!=0)
            if(buffer.type!='re')
            {
                let total = 0
                //Si Facture
                if( buffer.type =='fa' || buffer.type =='re' )
                    total = parseFloat((buffer.prix * ((buffer.tva/100)+1)).toFixed(2))
                    
                //Sinon avoir
                if( buffer.type =='av' )
                    total = parseFloat((buffer.prix * (( (buffer.tva*-1)/100)+1)).toFixed(2))

                if( buffer.credit!=0)
                    root.vente_seance += total
                else
                    root.vente_accesoire += total

            }

            
        }
    })
}

</script>

<style scoped>
.espace{
    padding-left: 5%;
}
</style>